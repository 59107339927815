import Common from 'data/constants/common';
import { Emitter_Keys } from 'data/constants/eventEmitterKeys';
import ImagesBase64 from 'data/constants/imageBase64';
import { WebviewCallbackKeys } from '../data/constants/webviewCallbackKeys';
import { emitter } from '../lib/emitter';
import DetailTransactionModel from 'types/detailTransactionModel';
import { CustomerKidType } from 'types/customerKidTypes';

export interface DownloadStatementDataProps {
  subSakuId: number;
  balance: number;
  title: string;
  coreCode: string;
  createDate: string;
}

export interface DownloadStatementDataSakuAnakProps {
  subSakuId: number;
  balance: number;
  title: string;
  parentName: string;
  coreCode: string;
  createDate: string | undefined;
  childsDOB: string | undefined;
  childsName: string | undefined;
  cif:
    | {
        number: string;
        name: string;
        address: {
          address: string;
          province: string;
          city: string;
          subdistrict: string;
          rt: string;
          rw: string;
        };
      }
    | undefined;
}

export interface DownloadReceiptProps {
  dateTime: string;
  amount: string;
  adminFee: string;
  userNameSource: string;
  bankSource: string;
  accountNumberSource: string;
  userNameDestination: string;
  bankDestination: string;
  bankCodeDestination: string;
  accountNumberDestination: string;
  nameOfSaku: string;
  transactionId: string;
  typeOfTransaction: string;
  purposeType: string;
  notes: string;
  status: string;
  transactionLogId: string;
  address: string;
  remark: string;
  bankDestinationIcon?: string;
}

export interface QrisRelatedProps {
  accountNumber: string;
  balance: number;
  customerName: string;
}

interface FetchCustomerDataCallbackProps {
  accountNumber: string;
  balance: number;
  customerName: string;
  parentName: string;
  phoneNumber: string;
  email: string;
  sakuId: number;
}

export const HandleWebviewCallback = {
  webviewLoaded: (appWindow: any) => {
    if (appWindow.current.flutter_inappwebview !== undefined) {
      appWindow.current.flutter_inappwebview.callHandler(WebviewCallbackKeys.webviewLoaded);
    }
  },

  verifyPin: (appWindow: any): any => {
    if (appWindow.current.flutter_inappwebview !== undefined) {
      return appWindow.current.flutter_inappwebview.callHandler(WebviewCallbackKeys.verifyPin);
    }

    return undefined;
  },

  clipboard: (appWindow: any, text: string) => {
    if (appWindow.current.flutter_inappwebview !== undefined) {
      appWindow.current.flutter_inappwebview.callHandler(WebviewCallbackKeys.clipoard, text);
    } else {
      navigator.clipboard.writeText(text);
    }
  },

  closeWebview: (appWindow: any) => {
    if (appWindow.current.flutter_inappwebview !== undefined) {
      appWindow.current.flutter_inappwebview.callHandler(WebviewCallbackKeys.closeWebview);
    }
  },

  closeWebviewWithoutConfirmation: (appWindow: any) => {
    if (appWindow.current.flutter_inappwebview !== undefined) {
      appWindow.current.flutter_inappwebview.callHandler(
        WebviewCallbackKeys.closeWebviewWithoutConfirmation
      );
    }
  },

  downloadStatement: (appWindow: any, args: DownloadStatementDataProps) => {
    if (appWindow.current.flutter_inappwebview !== undefined) {
      appWindow.current.flutter_inappwebview.callHandler(
        WebviewCallbackKeys.downloadStatement,
        args
      );
    }
  },

  downloadStatementSakuAnak: (appWindow: any, args: DownloadStatementDataSakuAnakProps) => {
    if (appWindow.current.flutter_inappwebview !== undefined) {
      appWindow.current.flutter_inappwebview.callHandler(
        WebviewCallbackKeys.downloadStatementSakuAnak,
        args
      );
    }
  },

  sapaRaya: (appWindow: any) => {
    if (navigator.onLine) {
      if (appWindow.current.flutter_inappwebview !== undefined) {
        appWindow.current.flutter_inappwebview.callHandler(WebviewCallbackKeys.sapaRaya);
      }
    } else {
      const errorProps = {
        isError: true,
        title: Common.lostConnectionTitle,
        description: Common.lostConnectionDescription,
        image: ImagesBase64.lostConnection,
      };

      emitter.emit(Emitter_Keys.SHOW_GLOBAL_ERROR, errorProps);
    }
  },

  openFaqView: (appWindow: any) => {
    if (navigator.onLine) {
      if (appWindow.current.flutter_inappwebview !== undefined) {
        appWindow.current.flutter_inappwebview.callHandler(WebviewCallbackKeys.openFaqView);
      }
    } else {
      const errorProps = {
        isError: true,
        title: Common.lostConnectionTitle,
        description: Common.lostConnectionDescription,
        image: ImagesBase64.lostConnection,
      };

      emitter.emit(Emitter_Keys.SHOW_GLOBAL_ERROR, errorProps);
    }
  },

  openFaqLottery: (appWindow: any) => {
    if (appWindow.current.flutter_inappwebview !== undefined) {
      return appWindow.current.flutter_inappwebview.callHandler(WebviewCallbackKeys.openFaqLottery);
    }
  },

  openSavingAccount: (appWindow: any) => {
    if (appWindow.current.flutter_inappwebview !== undefined) {
      appWindow.current.flutter_inappwebview.callHandler(WebviewCallbackKeys.openSavingAccount);
    }
  },

  shareReceipt: (
    appWindow: any,
    args: DownloadReceiptProps | DetailTransactionModel,
    icBankDestination: string
  ) => {
    if (appWindow.current.flutter_inappwebview !== undefined) {
      appWindow.current.flutter_inappwebview.callHandler(WebviewCallbackKeys.shareReceipt, [
        args,
        icBankDestination,
      ]);
    }
  },

  downloadReceipt: (
    appWindow: any,
    args: DownloadReceiptProps | DetailTransactionModel,
    icBankDestination: string
  ) => {
    if (appWindow.current.flutter_inappwebview !== undefined) {
      appWindow.current.flutter_inappwebview.callHandler(WebviewCallbackKeys.downloadReceipt, [
        args,
        icBankDestination,
      ]);
    }
  },

  changeAccount: (appWindow: any) => {
    if (appWindow.current.flutter_inappwebview !== undefined) {
      appWindow.current.flutter_inappwebview.callHandler(WebviewCallbackKeys.changeAccount);
    }
  },

  finishRegistration: (appWindow: any) => {
    if (appWindow.current.flutter_inappwebview !== undefined) {
      appWindow.current.flutter_inappwebview.callHandler(WebviewCallbackKeys.finishRegistration);
    }
  },

  downloadMassReceipt: (appWindow: any, args: any[]) => {
    if (appWindow.current.flutter_inappwebview !== undefined) {
      appWindow.current.flutter_inappwebview.callHandler(
        WebviewCallbackKeys.downloadMassReceipt,
        args
      );
    }
  },

  invitationSakuAnak: (appWindow: any, args: CustomerKidType) => {
    if (appWindow.current.flutter_inappwebview !== undefined) {
      appWindow.current.flutter_inappwebview.callHandler(
        WebviewCallbackKeys.invitationSakuAnak,
        args
      );
    }
  },

  photoYouthRejected: (appWindow: any, args: CustomerKidType) => {
    if (appWindow.current.flutter_inappwebview !== undefined) {
      appWindow.current.flutter_inappwebview.callHandler(
        WebviewCallbackKeys.photoYouthRejected,
        args
      );
    }
  },

  checkQuotaLiveness: (appWindow: any, text: string) => {
    if (appWindow.current.flutter_inappwebview !== undefined) {
      appWindow.current.flutter_inappwebview.callHandler(
        WebviewCallbackKeys.checkQuotaLiveness,
        text
      );
    } else {
      navigator.clipboard.writeText(text);
    }
  },

  closeSakuYouth: (appWindow: any) => {
    if (appWindow.current.flutter_inappwebview !== undefined) {
      appWindow.current.flutter_inappwebview.callHandler(WebviewCallbackKeys.closeSakuYouth);
    }
  },

  configurationSecureData: (appWindow: any, value: string) => {
    if (appWindow.current.flutter_inappwebview !== undefined) {
      appWindow.current.flutter_inappwebview.callHandler(
        WebviewCallbackKeys.configurationSecureData,
        value
      );
    } else {
      alert(
        'You need to open this feature from the Raya Digital Bank app. Because it is just only passing the callback value'
      );
    }
  },

  openSmartLogin: (appWindow: any, value: string) => {
    if (appWindow.current.flutter_inappwebview !== undefined) {
      return appWindow.current.flutter_inappwebview.callHandler(
        WebviewCallbackKeys.openSmartLogin,
        value
      );
    } else {
      alert(
        'You need to open this feature from the Raya Digital Bank app. Because it is just only passing the callback value'
      );
    }

    return undefined;
  },

  getValueSmartLogin: (appWindow: any) => {
    if (appWindow.current.flutter_inappwebview !== undefined) {
      return appWindow.current.flutter_inappwebview.callHandler(
        WebviewCallbackKeys.getValueSmartLogin
      );
    }

    return undefined;
  },

  openTrustDevice: (appWindow: any) => {
    if (appWindow.current.flutter_inappwebview !== undefined) {
      appWindow.current.flutter_inappwebview.callHandler(WebviewCallbackKeys.openTrustDevice);
    } else {
      alert(
        'You need to open this feature from the Raya Digital Bank app. Because it is just only passing the callback value'
      );
    }
  },

  openQrisView: (appWindow: any, args: QrisRelatedProps) => {
    if (appWindow.current.flutter_inappwebview !== undefined) {
      appWindow.current.flutter_inappwebview.callHandler(WebviewCallbackKeys.openQrisView, args);
    }
  },

  // TODO: The older version (shareReceipt & downloadReceipt) must be removed when adjusted the saku bisnis also
  shareDownloadInvoice: (appWindow: any, args: any) => {
    if (appWindow.current.flutter_inappwebview !== undefined) {
      appWindow.current.flutter_inappwebview.callHandler(
        WebviewCallbackKeys.shareDownloadInvoice,
        args
      );
    }
  },

  /**
   * This method is called on Dashboard API. It will return customerData required in the apps
   * @param appWindow = appWindow which had flutter_inappwebview
   * @param args = The data parameters which had `FetchCustomerDataCallbackProps` data type
   */
  fetchCustomerData: (appWindow: any, args: FetchCustomerDataCallbackProps) => {
    if (appWindow.current.flutter_inappwebview !== undefined) {
      appWindow.current.flutter_inappwebview.callHandler(
        WebviewCallbackKeys.fetchCustomerData,
        args
      );
    }
  },

  takePhoto: async (appWindow: any) => {
    if (appWindow.current.flutter_inappwebview !== undefined) {
      const res = await appWindow.current.flutter_inappwebview.callHandler(
        WebviewCallbackKeys.takePhoto
      );
      return res;
    }
  },

  handleChooseImageFromGallery: async (appWindow: any) => {
    if (appWindow.current.flutter_inappwebview !== undefined) {
      const res = await appWindow.current.flutter_inappwebview.callHandler(
        WebviewCallbackKeys.handleChooseImageFromGallery
      );
      return res;
    }
  },

  getPosAndPlace: async (appWindow: any) => {
    if (appWindow.current.flutter_inappwebview !== undefined) {
      const res = await appWindow.current.flutter_inappwebview.callHandler(
        WebviewCallbackKeys.getPosAndPlace
      );
      return res;
    }
  },

  shareQrisImage: async (appWindow: any, text: string) => {
    if (appWindow.current.flutter_inappwebview !== undefined) {
      appWindow.current.flutter_inappwebview.callHandler(WebviewCallbackKeys.shareQrisImage, text);
    }
  },

  downloadQrisImage: async (appWindow: any, text: string) => {
    if (appWindow.current.flutter_inappwebview !== undefined) {
      appWindow.current.flutter_inappwebview.callHandler(
        WebviewCallbackKeys.downloadQrisImage,
        text
      );
    }
  },

  openBrowser: async (appWindow: any, text: string) => {
    if (appWindow.current.flutter_inappwebview !== undefined) {
      appWindow.current.flutter_inappwebview.callHandler(WebviewCallbackKeys.openBrowser, text);
    }
  },

  handleLocationPermission: async (appWindow: any) => {
    if (appWindow.current.flutter_inappwebview !== undefined) {
      const res = await appWindow.current.flutter_inappwebview.callHandler(
        WebviewCallbackKeys.handleLocationPermission
      );
      return res;
    }
  },

  selectContact: async (appWindow: any) => {
    if (appWindow.current.flutter_inappwebview !== undefined) {
      const res = await appWindow.current.flutter_inappwebview.callHandler(
        WebviewCallbackKeys.selectContact
      );

      const selectedContact: {
        phoneNumber: string | null;
        alias: string | null;
        name: string | null;
      } = JSON.parse(res);

      return selectedContact;
    } else {
      alert(
        'You need to open this feature from the Raya Digital Bank app. Because it is just only passing the callback value'
      );
    }
  },

  shareInvitation: (appWindow: any, text: string) => {
    if (appWindow.current.flutter_inappwebview !== undefined) {
      appWindow.current.flutter_inappwebview.callHandler(WebviewCallbackKeys.shareInvitation, text);
    }
  },

  createPin: async (appWindow: any, args: CustomerKidType) => {
    if (appWindow.current.flutter_inappwebview !== undefined) {
      await appWindow.current.flutter_inappwebview.callHandler(
        WebviewCallbackKeys.createPinKid,
        args
      );
    }
  },

  isVccComplete: async (appWindow: any) => {
    if (appWindow.current.flutter_inappwebview) {
      const res = await appWindow.current.flutter_inappwebview.callHandler(
        WebviewCallbackKeys.getIsVccComplete
      );

      return res?.isVccComplete === true;
    }

    return false;
  },
};
